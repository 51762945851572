import React from 'react';

import './our-services.css';
import servicesImage from '../../assets/web-apps.png';

function OurServices() {
  return (
    <section id="our-services">
      <div className="container">
        <div className="row">
          <h1 className="services-title">O que fazemos?</h1>
          <div className="services-box">
            <div className="card mb-3 mt-5">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src={servicesImage}
                    className="card-img"
                    alt="Nossos Serviços"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text services-text">
                      Nosso propósito é tornar real sua ideia de negócio e
                      desenvolver uma solução através de ferramentas
                      tecnológicas modernas, seja em apps web, apps mobile para
                      Android/iOS ou mesmo através de serviços em nuvem como
                      AWS. Nosso conhecimento técnico dá aos nossos clientes a
                      certeza de apps seguros e escaláveis, que se integram com
                      o <span>SEU NEGÓCIO</span> e criam uma experiência de
                      valor para os
                      <span> SEUS CLIENTES</span>.
                    </p>
                    <p className="card-text services-text">
                      Criamos soluções sob-medida{' '}
                      <span>focadas nos resultados do negócio</span>. Sua ideia
                      planejada desde o início até o momento da implantação e
                      manutenção, garantindo robustez, resiliência e
                      disponibilidade.
                    </p>
                    <a
                      href="#contact-us"
                      className="btn btn-info mr-2 banner-button"
                      role="button"
                    >
                      faça um orçamento
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurServices;
