import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import './contact-us.css';
import { Load } from '../../components/Util/';

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório!'),
  email: yup
    .string()
    .required('Email é obrigatório!')
    .email('Email inválido.'),
  subject: yup.string().required('Assunto é obrigatório!'),
  description: yup.string().required('Descrição é obrigatório!'),
});

function ContactUs() {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });

  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);

  const showMessage = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 9000);
  };

  const onSubmit = (data, e) => {
    setLoad(true);
    setTimeout(() => {
      e.target.reset();
      setLoad(false);
      showMessage();
    }, 1000);
  };

  return (
    <section id="contact-us">
      <div className="container">
        <div className="row">
          <h1 className="contact-title">Fale conosco</h1>
          <div className="contact-box">
            <div className="container-fluid h-100">
              <div className="row justify-content-center align-items-center">
                <div className="col col-sm-8 col-md-8 col-lg-8 col-xl-6">
                  <form className="mb-5 mt-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className={
                          errors.name
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        placeholder="Nome"
                        ref={register}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className={
                          errors.email
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        placeholder="Email"
                        ref={register}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Telefone"
                        ref={register}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        className={
                          errors.subject
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        placeholder="Assunto"
                        ref={register}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="description"
                        className={
                          errors.description
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        placeholder="Descrição..."
                        rows="5"
                        ref={register}
                      ></textarea>
                    </div>
                    {Object.keys(errors).length > 0 && (
                      <p className="alert alert-danger">
                        Por favor! Verifique os campos <b>obrigatórios.</b>
                      </p>
                    )}

                    {load && <Load title="Enviando" />}

                    {show && (
                      <div className="alert alert-success" role="alert">
                        Enviado com <b>sucesso!</b>
                      </div>
                    )}

                    <button className="btn btn-info btn-block">enviar</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
