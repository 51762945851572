import React from 'react';

import './util.css';

export const Load = props => {
  return (
    <div
      className="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <span
        className="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {`  ${props.title}...`}
    </div>
  );
};
