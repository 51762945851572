import React from 'react';

import './banner.css';

function Banner() {
  return (
    <section name="home" id="home">
      <section
        className="d-flex align-items-center position-relative vh-100 cover hero"
        role="img"
        aria-label="CodApps - Banner"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="banner-title text-center">
                codamos ideias para tornar seu <span>projeto real</span>
              </h1>
              <div className="mt-3 text-center">
                <a
                  href="#contact-us"
                  className="btn btn-info mr-2 banner-button"
                  role="button"
                >
                  crie seu projeto
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Banner;
