import React from 'react';

import './about-us.css';

function AboutUs() {
  return (
    <section id="about-us">
      <div className="container">
        <div className="row">
          <h1 className="about-title"> Quem somos? </h1>
          <div className="about-box">
            <p className="about-text">
              <span>Desenvolvedores com sede de tecnologia e inovação</span>,
              somos amantes de desafios e satisfação em cada entrega a fim de
              tornar real cada ideia que é confiada em nossas mãos! Respiramos
              tecnologia, sempre buscamos mais e aprendemos mais com intuito de
              melhorar as entregas, tanto na agilidade quanto na qualidade.
              Estamos aqui pra somar, <span>tirar seu projeto do papel</span>,
              torna-lo real e fazer com que seu produto gere{' '}
              <span>valor com sotisfação</span> !
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
