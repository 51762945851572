import React, { useEffect } from 'react';

import { Navbar, Nav } from 'react-bootstrap';

import LogoSVG from '../../assets/logo.png';
import './header.css';

function Header() {
  useEffect(() => {
    const navBar = document.querySelector('nav');
    window.addEventListener('scroll', () => {
      // let bannerHeight = document.getElementById('home').clientHeight;
      window.scrollY > 100
        ? navBar.classList.add('bg-dark')
        : navBar.classList.remove('bg-dark');
    });
  });

  return (
    <header className="fixed-top page-header">
      <div className="container-fluid container-fluid-max">
        <Navbar variant="dark" expand="lg" fixed="top">
          <div className="container">
            <Navbar.Brand href="#home">
              <img src={LogoSVG} alt="CodApps" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="main-navbar-nav" />
            <Navbar.Collapse id="main-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#about-us">Sobre Nós</Nav.Link>
                <Nav.Link href="#our-services">Serviços</Nav.Link>
                <Nav.Link href="#contact-us">Contato</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </header>
  );
}

export default Header;
