import React from 'react';

import './footer.css';

function Footer() {
  return (
    <footer className="py-5 page-footer">
      <div className="container container-fluid-max">
        <div className="row">
          <div className="col-12 col-md-12 col-lx-12 footer-child copyright text-center">
            <p>
              Copyright &copy;2019 by <span>CodApps</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
